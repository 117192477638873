.dashboard-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  overflow: auto;
}

.header {
  display: flex;
  width: 99%;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 2px;
}


.charts-table-body {
  display: grid;
  grid-template-columns: 30% 69.5%;
  margin-top: 10px;
  gap: 10px;
  height: 73vh;
  width: 100%;
}

.charts {
  width: 98%;
  /* background-color: red; */
  display: flex !important;
  border: 1px solid rgb(170, 170, 170);
  background-color: #fff;
  border-radius: 2px;
  height: 73vh;
  /* background-color: red; */
}

.table {
  width: 100%;
  height: 73vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow-y: auto;
  overflow-x: hidden; */
  border: 1px solid rgb(170, 170, 170);
  border-radius: 2px;
}
@media screen and (min-width: 1550px) {
  .charts-table-body{
    height: 77vh;
  }
  .charts{
    height: 77vh;
    width: 98.4%;
  }
  .table{
    height: 77vh !important;
  }
};
  


/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1250px) {
  .charts-table-body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    
  }

  .charts {
    height: 400px;
    width: 100% !important;
    
  }
  .table {
    width: 100%;
    /* height: 70vh !important; */
   
  }
  .ant-table-header {
    width: 100%;
    height: 100% !important;
  }
}

@media screen and (max-width: 900px) {
  .header {
    height: 110px;
    align-items: center;
    justify-content: center;
  }
  
}

@media only screen and (min-width: 300px) and (max-width: 900px) { 
  .charts-table-body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    
  }

  .charts {
    height: 300px;
    width: 96% !important;
    
  }
  .table {
    width: 96%;
    /* height: 70vh !important; */
    font-size: medium;
   
  }
  .ant-table-header {
    width: 100%;
    height: 100% !important;
  }
  .header {
    height: 70px;
    align-items: center;
    justify-content: center;
    width: 96%;
  }
  .ant-table-row{
    font-size: 10px;
  }
  .ant-table-tbody > tr > td{
    padding: 0 !important;
  }
  
  
}
