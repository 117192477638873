.supportBody {
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 85%;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

}

.container .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container .content .left-side {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
}

.content .left-side::before {
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
}

.content .left-side .details {
    margin: 14px;
    text-align: center;
}

.content .left-side .details i {
    font-size: 30px;
    color: #3e2093;
    margin-bottom: 10px;
}

.content .left-side .details .topic {
    font-size: 18px;
    font-weight: 500;
}

.content .left-side .details .text-one,
.content .left-side .details .text-two {
    font-size: 14px;
    color: #afafb6;
}

.container .content .right-side {
    width: 75%;
    margin-left: 75px;
}

.content .right-side .topic-text {
    font-size: 29px;
    font-weight: 600;
    color: #3e2093;
    display: flex;
    justify-content: center;
    align-items: center;

}

.right-side .input-box {
    height: 50px;
    width: 100%;
    margin: 12px 0;
}

.right-side .input-box input,
.right-side .input-box textarea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0 15px;
}

.right-side .input-box1 input,
.right-side .input-box1 textarea {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0 15px;
}

.right-side .message-box {
    min-height: 110px;
}

.right-side .input-box textarea {
    padding-top: 6px;
}

.right-side .button {
    display: inline-block;
    margin-top: 12px;
}

.button {
    color: #fff;
    font-size: 18px;
    /* outline: none; */
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #f1b634;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px #999;
    
}
.button:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }


.button:hover {
    background: rgb(34, 177, 24);
}

@media (max-width: 950px) {
    .container {
        width: 90%;
        padding: 30px 40px 40px 35px;
    }

    .container .content .right-side {
        width: 75%;
        margin-left: 55px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 390px) {
    .container {
        margin: 40px 0;
        height: 80%;
        width: 76%;

    }

    .container .content {
        flex-direction: column-reverse;
    }

    .container .content .left-side {
        width: 100%;
        flex-direction: row;
        margin-top: 10px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .container .content .left-side::before {
        display: none;
    }

    .container .content .right-side {
        width: 100%;
        margin-left: 0;
    }
    .supportBody {
        height: 90%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
    }
    .left-side{
        margin-top: 40px !important;
    }
   
}

@media only screen and (min-width: 390px) and (max-width: 900px) {
    .container {
        /* margin: 40px 0; */
        height: 60%;
        margin-top: -120px;
    }

    .container .content {
        flex-direction: column-reverse;
    }

    .container .content .left-side {
        width: 100%;
        flex-direction: row;
        margin-top: 10px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .container .content .left-side::before {
        display: none;
    }

    .container .content .right-side {
        width: 100%;
        margin-left: 0;
    }
    .supportBody {
        height: 90%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
    }
    .left-side{
        margin-top: 70px !important;
    }
   
}
.alert{
    position: absolute;
    margin-top: -37px;
    margin-left: 150px;
    width: 200px;
    text-align: center;
}

