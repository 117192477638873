.tabbar-container {
  display: flex;
  width: 100%;
  height: 60px;
  background-color: rgb(107 114 128);
  justify-content: end;
}

.tabbar-icon {
  opacity: 0;
  display: none;
  cursor: pointer;
  color: #fff;
}

.logout-container {
  display: "flex";
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 5px;
  margin-top: 13px;
  margin-right: 40px;
}

@media screen and (max-width: 1250px) {
  .tabbar-icon {
    opacity: 1;
    display: block;
    margin: 10px;
  }
  .tabbar-container {
    justify-content: space-between;
  }
}
.logoutbutton{
  border: none;
  background-color: transparent;
  margin-right: 40px;
  margin-top: 12px;
}


.switch{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  gap: 15px;
  color: #fff;
  width: 100%;
}
