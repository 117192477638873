.ant-table-pagination {
  display: none !important;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #dde2fc;
}
.excel {
  z-index: 10;
  background-color: #fafafa;
  cursor: pointer;
  right: 0;
  position: absolute;
}
.chartTable {
  width: 100%;
  position: relative;
  height: 100%;
}
