.navbar {
  /* background-color: rgb(25 89 99); */
  background-color: rgb(107 114 128);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 200px !important;
  /* padding-top: 15px; */
  padding: 15px;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: rgb(25 89 99);
  width: 100%;
  display: flex;
  justify-content: center;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  /* justify-content: start; */
  /* align-items: center; */
  padding: 2px 0px 2px -3px;
  list-style: none;
  height: 60px;
  
}


.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text-sub {
  display: flex;
  /* justify-content: start; */
  /* align-items: center; */
  padding: 2px 0px 2px -3px;
  list-style: none;
  height: 60px;
  
}


.nav-text-sub a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  margin-left: 40px;
}

/* .nav-text a:hover {
  background-color: #1a83ff;
} */

.nav-menu-items {
  width: 100%;
  padding: 10px;
}

.navbar-toggle {
  background-color: rgb(25 89 99);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.sideItem li span {
  margin-left: 16px;
}

.sideItem {
  margin-top: 80px;
}

.copyRight {
  font-size: 12px;
  bottom: 0;
  position: absolute;
  color: #fff;
  text-align: center;
  margin-bottom: 8px;
}

.close-icon {
  display: none;
}

@media screen and (max-width: 1250px) {
  .navbar {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    display: none;
    height: 100%;
  }

  .close-icon {
    display: block;
    font-weight: bold;
    font-size: 30px;
    color: #fff;
    position: absolute;
    left: 85%;
    top: 11px;
    cursor: pointer;
    transition: all;
    transition-duration: 200ms;
  }

  .close-icon:hover {
    color: #f1b634;
  }
}
