.loginPage{
    background-image: url("../../assets/image/net.jpg");
    height: 100vh;
    position: relative;
    display: flex;
    width: 100vw;
    background-repeat:no-repeat;
    background-size: 100% 100%;
    
   
}
.cardLogin{
    width: 410px;
    height: 300px;
    background-color: rgb(255, 255, 255, 0.6);
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    border: none;
    
}
.cardContent{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.cardContent button{
    margin: auto;
    width: 100px;
    height: 50px;
    background-color: #f1b634;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 2px #999;
}
.cardContent button:hover {
    background-color: #5ec061
}

.cardContent button:active {
  background-color: #3e8e41;
  box-shadow: 0 2px #666;
  transform: translateY(4px);
}