.dateTimeBox {
  height: 40px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
}

.dateLabel {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 500;
  white-space: nowrap;
  width: 120px;
}
.dateTimeBox button {
  margin-top: 22px;
  background-color: rgb(102, 102, 218);
  color: #fff;
}
.models {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.models select {
  height: 31px;
  width: 140px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.createdAt {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding: 0;
  opacity: 0.6;
  margin-top: 70px;
}
.datepicker{
  width: 150px;
}
.dateTimeBoxDiv{
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: end;
}

@media screen and (max-width: 900px) {
  .dateTimeBox {
    flex-direction: column;
    gap: 0;
  }
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
  .dateTimeBox {
    
    background-color: #fff;
    display: flex;
    flex-direction: row;
    gap: 3px;
    padding: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .dateLabel {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 500;
    white-space: nowrap;
    font-size: 10px;
  }
  .dateTimeBox button {
    margin-top: 22px;
    background-color: rgb(102, 102, 218);
    color: #fff;
    
  }
  .models {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 10px;

  }
  
  .models select {
    height:25px;
    width: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    font-size: 10px;
  }
  .datepicker{
    width: 100px;
  }
  
  .dateTimeBox{
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    height: 15px;

  }
  .createdAt{
    margin-top: -9px;
  }
  .dateTimeBoxDiv{
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: -50px;
    gap: 3px !important;
  }
  .ant-picker-input > input{
    font-size: 10px !important;
  }
 }

