body {
  background-color: rgba(235, 241, 250);
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
