.home-layout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
}

@media screen and (max-width: 1250px) {
  .home-layout-container {
    width: 100vw !important;
  }
}
