.fileUpload {
    width: max-content!important;
    padding: 70px;
    border: 1px solid gray;
    display: flex;
    margin: auto;
    align-items: center;
    margin-top: 10%;
    background-color: #fff;
    border-radius: 30px;
}

.fileUpload button {
    font-weight: bold;
    color: dodgerblue;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
}

.fileUpload button:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}



input[type="file" i]::file-selector-button {
    font-weight: bold;
    color: dodgerblue;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
}

