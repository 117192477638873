.reports-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: scroll;
  padding: 10px;
}

.headerReports {
  display: flex;
  width: 99%;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 2px;
  height: 100px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-color: #fff;
  gap: 5px;
}
.headerReports label {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.headerReports select {
  height: 40px;
  margin: auto;
  width: 200px;
  text-align: center;
}

.selectDateReports {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  flex-direction: column;
}

.charts-table-body-Reports {
  display: flex;
  flex-direction: row;
  /* width: 100% !important; */
  margin-top: 10px;
  gap: 10px;
  height: 75vh;
  width: 100%;
}

.chartsReports {
  width: 80vw;
  /* background-color: red; */
  display: flex !important;
  border: 1px solid rgb(170, 170, 170);
  background-color: #fff;
  border-radius: 2px;
}

.tableReports {
  width: 300px;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow-y: auto;
    overflow-x: hidden; */
  border: 1px solid rgb(170, 170, 170);
  border-radius: 2px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 1400px) {
  .chartsReports {
    width: 80% !important;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1250px) {
  .charts-table-body-Reports {
    flex-direction: column;
    height: 100%;
  }

  .chartsReports {
    height: 500px;
    width: 100% !important;
  }
  .tableReports {
    width: 100%;
    height: 500px;
  }
  .ant-table-header {
    width: 100%;
    height: 100% !important;
  }
}

@media screen and (max-width: 900px) {
  .headerReports {
    height: 120px;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 300px) and (max-width: 900px) { 
  .charts-table-body-Reports {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    
  }

  .chartsReports {
    height: 300px;
    width: 96% !important;
    
  }
  .tableReports {
    width: 96%;
    /* height: 70vh !important; */
    font-size: medium;
   
  }
  .ant-table-header {
    width: 100%;
    height: 100% !important;
  }
  .headerReports {
    height: 70px;
    align-items: center;
    justify-content: center;
    width: 96%;
    font-size: 13px;
  }
  .ant-table-row{
    font-size: 10px;
  }
  .ant-table-tbody > tr > td{
    padding: 0 !important;
  }
  .headerReports > div >select{
    width: 150px;
  }
  .headerReports>label{
    margin-left: -60px;
  }
  
}
.headerReports > div> a{
  position: absolute;
  right: 0;
  margin-right: 95px;
}
.headerReports >div> a >img{
  /* margin-top: 40px; */
  margin-left: 60px;

}
.headerReports > div> p{
  position: absolute;
  right: 0;
  margin-right: 40px;
  margin-top: -30px;
}
